import {
  Button,
  makeStyles,
  Step,
  StepConnector,
  StepLabel,
  Stepper,
  Typography,
  withStyles,
} from "@material-ui/core"
import React, { useEffect, useState } from "react"
import AddClubForm from "../../components/Drawer/Screen/Forms/AddClubForm"
import AddCourtForm from "../../components/Drawer/Screen/Forms/AddCourtForm"
import BillingForm from "../../components/Drawer/Screen/Forms/BillingForm"
import useStyles from "./styles"
import clsx from "clsx"
import PropTypes from "prop-types"
import activeLine from "../../../images/active_line.svg"
import disableLine from "../../../images/dis_line.svg"
import DrawerSide from "../../components/DrawerSide"
import { Link as GatsByLink, navigate } from "gatsby"
import { ButtonColor } from "../../../helper/ButtonColor"
import { useSelector, useDispatch } from "react-redux"
import apiClient from "../../../api/apiClient"
import { clubsApi } from "../../../api/apiRequests"
import Loader from "../../components/Loader/Loader"
import { loginWithToken } from "../../../redux/slices/userSlice"
import ErrorAlert from "../../components/modals/errorAlert"
import { setErrorAlert } from "../../../redux/slices/errorAlertSlice"
import { setTrialModal } from "../../../redux/slices/subscriptionModalSlice"
import { actions } from "../../../redux/slices/clubSlice"
import Head from "../../components/Head"

const QontoConnector = withStyles({
  alternativeLabel: {
    top: 10,
  },
  active: {
    "& $line": {
      borderColor: "#784af4",
    },
  },
  completed: {
    "& $line": {
      borderColor: "#784af4",
    },
  },
  line: {
    borderColor: "#eaeaf0",
    borderTopWidth: 3,
    borderRadius: 1,
    display: "none",
  },
})(StepConnector)

const useQontoStepIconStyles = makeStyles({
  root: {
    color: "#eaeaf0",
    display: "flex",
    height: 22,
    alignItems: "center",
  },
  active: {
    color: "#784af4",
  },
  circle: {
    width: "200px",
    height: 2,
    borderBottom: "2px",
    backgroundColor: "currentColor",
  },
  completed: {
    color: "#784af4",
    zIndex: 1,
    fontSize: 18,
  },
})

const useStyle = makeStyles(theme => ({
  root: {
    backgroundColor: "transparent",
  },
  rootMain: {
    backgroundColor: "#F9FAFC",
    borderRadius: "15px",
    paddingBottom: "20%",
    paddingTop: "20px",
    width: "100%",
    marginLeft: "26px",
    marginTop: "100px",
  },
  buttonBack: {
    "text-transform": "capitalize",
    backgroundColor: "#B7B7BF",
    "&:focus": {
      outline: 0,
      border: 0,
    },
    marginRight: "20px",
    // marginLeft: "-20px",
    color: "white",
    width: "25%",
  },
  buttonNext: {
    "text-transform": "capitalize",
    backgroundColor: `${ButtonColor}`,
    "&:hover": {
      backgroundColor: `${ButtonColor}`,
    },
    "&:focus": {
      outline: 0,
      border: 0,
      backgroundColor: `${ButtonColor}`,
    },
    marginLeft: "10px",
    color: "white",
    width: "35%",
  },
  buttonSkip: {
    width: "400px",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  buttonRoot: {
    // margin: "10px",
    width: "80%",
    marginTop: "40px",
    marginLeft: "45px",
    display: "flex",
    // padding:"24px"
    // justifyContent: "space-evenly",
  },
  ButtonFinal: {
    color: "white",
    width: "25%",
    backgroundColor: `${ButtonColor}`,
    boxShadow: "NONE",
    justifyContent: "CENTER",
    display: "FLEX",
    textDecoration: "NONE",
    marginLeft: "0PX",
    borderRadius: "7PX",
    "&:hover": {
      backgroundColor: `${ButtonColor} !important`,
      boxShadow: "NONE",
    },
    textTransform: "none",
  },

  containerClass: {
    display: "flex",
  },
}))

function _renderStepContent(
  step,
  validation,
  formErrors,
  setFormErrors,
  newPassword,
  setNewPassword,
  confirmPassword,
  setConfirmPassword
) {
  switch (step) {
    case 0:
      return <AddClubForm validation={validation} />
    case 1:
      return (
        <AddClubForm
          validation={validation}
          errors={formErrors}
          setFormsErrors={value => {
            setFormErrors(value)
          }}
        />
      )
    case 2:
      return (
        <AddCourtForm
          validation={validation}
          errors={formErrors}
          setFormsErrors={value => {
            setFormErrors(value)
          }}
        />
      )
    case 3:
      return (
        <BillingForm
          errors={formErrors}
          setFormsErrors={value => {
            setFormErrors(value)
          }}
          validation={validation}
          newPassword={newPassword}
          setNewPassword={value => {
            setNewPassword(value)
          }}
          confirmPassword={confirmPassword}
          setConfirmPassword={value => {
            setConfirmPassword(value)
          }}
        />
      )
  }
}

function getSteps() {
  return ["Select campaign settings", "Create an ad group", "Create an ad"]
}

function QontoStepIcon(props) {
  const classes = useQontoStepIconStyles()
  const { active, completed } = props

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {completed ? (
        <img src={activeLine} alt="active-step" />
      ) : (
        <img src={disableLine} alt="inactive-step" />
      )}
    </div>
  )
}

QontoStepIcon.propTypes = {
  /**
   * Whether this step is active.
   */
  active: PropTypes.bool,
  /**
   * Mark the step as completed. Is passed to child components.
   */
  completed: PropTypes.bool,

  isActive: PropTypes.bool,
}

function StapperMain() {
  const classes = useStyles()
  const classesforStpper = useStyle()

  const dispatch = useDispatch()

  const clubInfo = useSelector(state => state.clubReducer.clubInfo)
  const courtInfo = useSelector(state => state.clubReducer.courtInfo)
  const submitState = useSelector(state => state.clubReducer)
  const billingState = useSelector(state => state.clubReducer.billingInfo)
  const user = useSelector(state => state.userSlice.user)

  const [formErrors, setFormsErrors] = useState({
    courts: {},
    clubInfo: {},
    billingInfo: {},
  })
  const [loading, setLoading] = useState(false)
  const [activeStep, setActiveStep] = useState(1)
  const [validation, setValidation] = useState(false)
  const [newPassword, setNewPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")
  const [skipCourt, setSkipCourt] = useState(false)

  const addCourtValidation = index => {
    const temp = { ...courtInfo[index] }
    let err = {}
    Object.keys(temp).map(key => {
      if (temp[key] === "") {
        err = { ...err, [key]: `Required*` }
      }
    })
    return err
  }

  const addClubInfoValidation = index => {
    const temp = { ...clubInfo }
    let err = {}
    Object.keys(temp).map(key => {
      if (temp[key] === "" && key !== "clubWebsite" && key !== "clubImage") {
        err = { ...err, [key]: `Required*` }
      }
    })
    return err
  }

  const addBillingInfoValidation = index => {
    const temp = { ...billingState }
    let err = {}
    Object.keys(temp).map(key => {
      if (temp[key] === "") {
        err = { ...err, [key]: `Required*` }
      }
      if (key === "email" && temp[key] !== "") {
        let matchPattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
          temp[key]
        )

        if (!matchPattern) {
          err = { ...err, [key]: "Enter a valid email address" }
        }
      }
    })

    if (newPassword === "") {
      err = {
        ...err,
        password:
          "The password field cannot be left blank. Please enter a password.",
      }
    }
    if (confirmPassword === "") {
      err = {
        ...err,
        confirmPassword:
          "Please confirm your password. The password fields do not match.",
      }
    }
    if (newPassword.length < 8) {
      err = {
        ...err,
        password: "Password must be at least 8 characters long",
      }
    }
    if (confirmPassword.length < 8) {
      err = {
        ...err,
        confirmPassword:
          "Please confirm your password. The password fields do not match.",
      }
    }
    if (newPassword !== confirmPassword) {
      err = {
        ...err,
        confirmPassword:
          "Please confirm your password. The password fields do not match.",
      }
    }
    return err
  }

  useEffect(() => {
    if (window.location.search) {
      const step = new URLSearchParams(window.location.search).get("step")
      setActiveStep(Number.parseInt(step))
    }
  }, [])

  // useEffect(() => {
  //   if (activeStep) {
  //     navigate(`?step=${activeStep}`)
  //   }
  // }, [activeStep])

  useEffect(() => {
    if (loading) {
      window.scrollTo(0, 0)
    }
  }, [loading])

  useEffect(() => {
    if (
      !clubInfo.name ||
      !clubInfo.address ||
      !clubInfo.postalCode ||
      !clubInfo.clubEmail ||
      !clubInfo.phoneNumber ||
      !clubInfo.clubLocations ||
      !clubInfo.clubPhoneCode
    ) {
      setActiveStep(1)
      return
    } else if (
      !courtInfo.courtName &&
      !courtInfo.courtType &&
      !courtInfo.sports &&
      !courtInfo.status &&
      !courtInfo.bookingLength &&
      !courtInfo.currency &&
      !courtInfo.price
    ) {
      setActiveStep(2)
      return
    }
  }, [clubInfo])

  useEffect(() => {
    if (user?.uid) {
      navigate("/venue/schedule")
    }
  }, [user?.uid])

  const steps = getSteps()

  const handleNext = () => {
    setValidation(true)
    let errors = {
      courts: {},
      clubInfo: {},
      billingInfo: {},
    }

    if (activeStep === 1) {
      let err = addClubInfoValidation()

      if (Object.keys(err).length > 0) {
        errors["clubInfo"] = { ...errors["clubInfo"], ...err }
        dispatch(
          setErrorAlert({
            isVisible: true,
            message: "Please fill in all required fields.",
            title: "error",
            severity: "error",
            duration: 5000,
          })
        )
      }

      if (Object.keys(errors["clubInfo"]).length === 0) {
        setFormsErrors({ ...formErrors, clubInfo: {} })
        setActiveStep(prevActiveStep => prevActiveStep + 1)
        setValidation(false)
      }
      setFormsErrors({ ...formErrors, ...errors })
    }

    if (activeStep === 2) {
      courtInfo.map((item, index) => {
        let err = addCourtValidation(index)

        if (Object.keys(err).length > 0) {
          errors["courts"] = { ...errors["courts"], [index]: err }
          dispatch(
            setErrorAlert({
              isVisible: true,
              message: "Please fill in all required fields.",
              title: "error",
              severity: "error",
              duration: 5000,
            })
          )
        }
      })

      if (Object.keys(errors["courts"]).length === 0) {
        setFormsErrors({ ...formErrors, courts: {} })
        setActiveStep(prevActiveStep => prevActiveStep + 1)
        setValidation(false)
      }
      setFormsErrors({ ...formErrors, ...errors })
    }

    if (activeStep === 3) {
      let err = addBillingInfoValidation()

      if (Object.keys(err).length > 0) {
        errors["billingInfo"] = { ...errors["billingInfo"], ...err }
        setFormsErrors({ ...formErrors, ...errors })
      } else if (Object.keys(errors["billingInfo"]).length === 0) {
        setFormsErrors({ ...formErrors, billingInfo: {} })
        setValidation(false)
        handleSubmit()
        localStorage.setItem("drawer", true)
      }
    }
  }

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1)
    setSkipCourt(false)
  }

  const handleSkip = () => {
    setFormsErrors({
      ...formErrors,
      courts: {},
    })
    setSkipCourt(true)
    setActiveStep(prevActiveStep => prevActiveStep + 1)
  }

  const handleSubmit = async () => {
    setLoading(true)
    const API_KEY = process.env.GATSBY_GOOGLE_MAPS_API
    const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${submitState.clubInfo.address}+${submitState.clubInfo.clubLocations.label}&key=${API_KEY}`
    const res = await fetch(url, {
      method: "GET",
    })

    const data = await res.json()

    let sports = submitState.courtInfo.map(item => item.sports.value)

    try {
      const res = await apiClient(
        clubsApi.addClub({
          userInfo: {
            ...submitState.billingInfo,
            password: newPassword,
          },
          courtInfo: !skipCourt ? submitState.courtInfo : [],
          clubInfo: {
            ...submitState.clubInfo,
            location: {
              name: submitState?.clubInfo?.clubName,
              latitude: data.results[0].geometry.location.lat,
              longitude: data.results[0].geometry.location.lng,
              sports,
              address: submitState.clubInfo.address,
            },
          },
        })
      )
      const name = billingState?.name
      const token = res.data.token
      dispatch(loginWithToken({ token, name, ...res?.data }))

      if (res?.data?.success) {
        dispatch(actions.removeInfo())
      }

      dispatch(
        setErrorAlert({
          isVisible: true,
          message: "Sign up successfull!",
          title: "success",
          severity: "success",
          duration: 3000,
        })
      )
      setTimeout(() => dispatch(setTrialModal(true)), 2000)
    } catch (error) {
      setLoading(false)
      dispatch(
        setErrorAlert({
          isVisible: true,
          message: error?.response?.data?.error?.message,
          title: "error",
          severity: "error",
          duration: 3000,
        })
      )
      console.log("error submit", error.response)
    }
  }

  const handleReset = () => {
    setActiveStep(0)
  }

  if (loading) {
    return <Loader />
  }

  return (
    <>
      <Head pageTitle="Sign Up" />
      <DrawerSide>
        <ErrorAlert />
        <div className={classesforStpper.rootMain}>
          <div
            style={{
              justifyContent: "left",
              display: "flex",
            }}
          >
            <div style={{ width: "fit-content" }}>
              <Stepper
                className={classesforStpper.root}
                activeStep={activeStep}
                connector={<QontoConnector />}
              >
                {steps.map(label => (
                  <Step active={activeStep === 0 ? true : false} key={label}>
                    {" "}
                    <StepLabel StepIconComponent={QontoStepIcon}></StepLabel>
                  </Step>
                ))}
              </Stepper>
            </div>
          </div>

          <div>
            {activeStep === steps.length + 1 ? (
              <div>
                <Typography className={classes.instructions}>
                  All steps completed - you&apos;re finished
                </Typography>
                <Button onClick={handleReset} className={classes.button}>
                  Reset
                </Button>
              </div>
            ) : (
              <>
                {_renderStepContent(
                  activeStep,
                  validation,
                  formErrors,
                  setFormsErrors,
                  newPassword,
                  setNewPassword,
                  confirmPassword,
                  setConfirmPassword
                )}
                <div
                  style={{
                    width: "526px",
                    paddingLeft: "67px",
                    marginTop: "40px",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Button
                    disableRipple
                    disableElevation
                    disabled={activeStep === 1}
                    onClick={handleBack}
                    className={classesforStpper.buttonBack}
                  >
                    Back
                  </Button>
                  {activeStep === 3 ? (
                    <GatsByLink className={classesforStpper.ButtonFinal}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleNext}
                        focusRipple={false}
                        disableRipple
                        className={classesforStpper.ButtonFinal}
                      >
                        Finish
                      </Button>
                    </GatsByLink>
                  ) : (
                    <div className={classesforStpper.buttonSkip}>
                      {activeStep === 2 && (
                        <Button
                          style={{ textTransform: "capitalize" }}
                          onClick={handleSkip}
                        >
                          Skip
                        </Button>
                      )}
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleNext}
                        focusRipple={false}
                        disableRipple
                        className={classesforStpper.buttonNext}
                      >
                        Next
                      </Button>
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </DrawerSide>
    </>
  )
}

export default StapperMain
